@use '../../../../node_modules/@angular/material/index' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// **Be sure that you only ever include this mixin once!**
@include mat.core();

// Define the theme.
$candy-app-primary: mat.define-palette(mat.$indigo-palette);
$candy-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$candy-app-theme: mat.define-light-theme((color: (primary: $candy-app-primary,
        accent: $candy-app-accent,
      ),
    ));

// Include the theme styles for only specified components.
@include mat.checkbox-theme($candy-app-theme);


@media (max-width: 1024px) {
  .mobile-dialog-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #f4f4f4;
    display: none;
    z-index: 44444;
  }

  .cdk-global-scrollblock {
    .mobile-dialog-overlay {
      display: block;
    }
  }
}
