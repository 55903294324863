@import 'ngx-sharebuttons/themes/default/default-theme';

@import 'styles/myboostrap';

@import 'styles/material';
@import 'styles/fonts';
@import 'styles/common';
@import 'styles/responsive';

@import '@angular/cdk/overlay-prebuilt.css';

$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import '@fortawesome/fontawesome-free/scss/fontawesome';
@import '@fortawesome/fontawesome-free/scss/solid';
@import '@fortawesome/fontawesome-free/scss/regular';
@import '@fortawesome/fontawesome-free/scss/brands';
