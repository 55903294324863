body {
  position: relative;
  padding: 0 !important;
  margin: 0;
  width: 100%;
  background: #f4f4f4;
  font-size: 14px;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

img {
  vertical-align: middle;
}

h1,
h2,
h3 {
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 0;
}

.scroller {
  overflow-y: auto;
  scrollbar-color: #f93039 rgb(233, 233, 233);
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgb(233, 233, 233);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #f93039;
  }
}

.wrapper {
  width: 1500px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
}

.regular-container {
  background-color: #fff;
  border-radius: 15px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pointer {
  cursor: pointer;
}

.mat-form-field {
  position: relative !important;

  .mat-select {
    background: #f4f4f4 !important;
    border-radius: 15px !important;
    color: #2f3031 !important;
    font-family: 'HNeue55tdRoman' !important;
    font-feature-settings: 'case' on !important;
    font-size: 12px !important;
    padding: 6px 15px 10px 17px !important;

    .mat-select-arrow {
      border-top-color: #aaa !important;
    }
  }
}

.mat-select-panel {
  border-radius: 15px !important;
  background: #f4f4f4 !important;
  border: transparent !important;
  overflow-y: auto !important;
  z-index: 1000 !important;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175) !important;

  .mat-option {
    color: #2f3031 !important;
    font-family: 'HNeue55tdRoman' !important;
    font-feature-settings: 'case' on !important;
    font-size: 13px !important;
    cursor: pointer !important;

    &:hover,
    &.mat-active {
      background: #f93039 !important;
      color: #fff !important;
    }
  }
}

.mat-checkbox-layout {
  margin-bottom: 0 !important;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.07) !important;
}

.cdk-overlay-container {
  z-index: 999999 !important;
}

.news-description>* {
  max-width: 100%;

  iframe,
  img,
  video {
    max-width: 100%;
  }
}

// share socials
.sb-wrapper {
  height: 32px !important;
  width: 32px !important;
  min-width: auto !important;
  line-height: 32px !important;

  .sb-icon {
    font-size: 14px !important;
  }
}

// video player
.video-player-wrapper,
.news-video,
.news-description {
  iframe {
    width: 655px;
    height: 372px;
    border: 0;
    margin: 0 auto;
    display: block;
  }
}

// banners
.web-promotion {
  position: relative;
  width: 100%;
  border-radius: 2px;
  overflow: hidden;
  //background-color: #f1eeee;
  z-index: 10;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.web-top {
    min-width: 980px;
    height: 100px;
    margin: 0 auto 10px auto;
  }

  &.web-left-side {
    position: fixed;
    width: 130px;
    height: 500px;
    top: 164px;
    left: 5px;
  }

  &.web-right-side {
    position: fixed;
    width: 130px;
    height: 500px;
    top: 164px;
    right: 5px;
  }

  &.web-sidebar {
    width: 255px;
    height: 255px;
    margin: 0 auto 20px auto;
  }

  &.web-popup {
    position: fixed;
    width: 300px;
    height: 300px;
    bottom: 30px;
    right: -300px;
    animation: bannerFromRight 300ms forwards 2s;
  }

  &.web-news-top,
  &.web-news-bottom {
    min-width: 620px;
    height: 120px;
    margin: 0 auto 10px auto;
  }
}

.mobile-promotion {
  position: relative;
  overflow: hidden;
  //background-color: #f1eeee;
  z-index: 10;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.mobile-catfish {
    position: fixed;
    width: 100%;
    min-width: 320px;
    height: 100px;
    bottom: 150px;
    right: 0;
    left: 0;
    margin: 0 auto;
    transform: translateY(100px);
    animation: bannerFromBottom 300ms forwards 1s;
  }

  &.mobile-home-top,
  &.mobile-home-middle,
  &.mobile-news-item,
  &.mobile-news-top,
  &.mobile-news-bottom {
    width: 100%;
    min-width: 300px;
    height: 150px;
    margin: 10px auto 20px auto;
  }
}

#top-ge-counter-container {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }
}

.disable-scroll {
  overflow: hidden;
}

// iOS ONLY
@supports (-webkit-touch-callout: none) {
  .custom-shadow {
    display: none !important;
  }
}

@keyframes bannerFromRight {
  0% {
    right: -300px;
  }

  100% {
    right: 0;
  }
}
