@font-face {
  font-family: 'HNeue75tdBd';
  src: url('../assets/fonts/HelveticaNeueWorld-75Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/HelveticaNeueWorld-75Bold.woff2') format('woff2'),
    url('../assets/fonts/HelveticaNeueWorld-75Bold.woff') format('woff'),
    url('../assets/fonts/HelveticaNeueWorld-75Bold.ttf') format('truetype'),
    url('../assets/fonts/HelveticaNeueWorld-75Bold.svg#HelveticaNeueWorld-75Bold')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HNeue55tdRoman';
  src: url('../assets/fonts/HelveticaNeueWorld-55Roman.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/HelveticaNeueWorld-55Roman.woff2') format('woff2'),
    url('../assets/fonts/HelveticaNeueWorld-55Roman.woff') format('woff'),
    url('../assets/fonts/HelveticaNeueWorld-55Roman.ttf') format('truetype'),
    url('../assets/fonts/HelveticaNeueWorld-55Roman.svg#HelveticaNeueWorld-55Roman')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//     font-family: 'bpg_algeti';
//     src: url('../fonts/BPG_Algeti_2018.ttf') format('truetype');
// }
