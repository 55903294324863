// DESKTOP Custom Breakpoints

.mobile-screen {
  display: none !important;
}

@media (max-width: 1600px) {

  // COMPS
  .league-select {
    .league-select-btn {
      height: 50px !important;
      padding: 0 12px !important;
      font-size: 13px !important;

      .option-image {
        margin-right: 8px !important;
      }
    }

    .league-select-menu {
      top: 50px !important;

      .league-select-search {
        padding: 0 10px !important;
      }
    }
  }

  // SHARED ITEMS
  .statistics {
    .player {
      margin-bottom: 4px !important;

      .player-name {
        width: calc(100% - 85px) !important;
      }

      .player-point {
        width: 45px !important;
      }
    }
  }

  .statistic-box {
    .statistic-header {
      height: 40px !important;
      padding-bottom: 15px !important;
      font-size: 13px !important;
    }

    .statistic-normal-container {
      padding: 15px !important;
    }
  }

  // HEADER
  .nav {
    .nav-items {
      .nav-item {
        .nav-link {
          font-size: 12.5px;
        }
      }
    }
  }

  // TODAY MATCHES
  .today-matches {
    .today-matches-header {
      .today-matches-tabs {
        .tabs-fixed {
          width: 170px !important;
        }

        .tabs-swiper {
          width: calc(100% - 170px) !important;
        }

        .league {
          font-size: 11px !important;
        }
      }
    }
  }

  // MY TEAMS
  .my-teams {
    .my-teams-content {
      .my-teams-news-item {
        height: 300px !important;

        .my-teams-news-item-title {
          height: 85px !important;
          padding: 10px 15px !important;
          font-size: 13px;
          margin-bottom: 0 !important;
        }

        .my-teams-news-item-date {
          padding: 0 15px 10px !important;
        }
      }

      .my-team-matches {
        padding: 10px !important;

        .my-teams-matches-title {
          font-size: 15px !important;
        }

        .my-teams-matches-content {
          padding: 5px 8px !important;

          .my-teams-match {
            .participants {
              padding: 4px 0 !important;
            }
          }
        }
      }

      .standings {
        height: 475px !important;

        .standings-title {
          padding: 15px 15px 0 !important;
          font-size: 15px !important;
        }

        .standings-content {
          height: 436px !important;
          padding: 15px !important;
        }
      }

      .statistics {
        .statistics-title {
          padding: 15px 15px 0 !important;
          font-size: 15px !important;
        }

        .statistics-content {
          padding: 15px !important;
        }
      }
    }
  }

  // SIDEBAR
  .sidebar-news {
    .sidebar-news-header {
      padding: 0 15px !important;
      font-size: 14px !important;
      height: 60px !important;
    }

    .sidebar-news-items {
      .sidebar-news-item {
        padding: 10px !important;

        .sidebar-news-item-info:not(.mobile-news-item-info) {
          width: 45px !important;
          height: 45px !important;

          .sidebar-news-item-date {
            font-size: 12.5px !important;
            margin-bottom: 2px !important;
          }
        }

        .sidebar-news-item-title {
          width: calc(100% - 50px) !important;
          font-size: 12.5px !important;
        }
      }
    }

    .sidebar-news-more {
      height: 60px !important;
      font-size: 13px !important;
    }
  }

  .sidebar-tables {
    padding: 15px !important;
  }

  .sidebar-statistics {
    padding: 15px !important;

    .sidebar-statistics-header {
      padding-bottom: 15px !important;
      font-size: 14px !important;
    }
  }

  // MAIN NEWS
  .main-news {
    .main-news-header {
      height: 70px !important;

      .main-news-title {
        font-size: 15px !important;
      }
    }
  }

  .main-news-item {
    .main-news-item-image-wrapper {
      width: 250px !important;
    }

    .main-news-item-content {
      width: calc(100% - 250px) !important;

      .main-news-item-title {
        padding: 15px 20px 15px !important;
        font-size: 15px !important;
      }

      .main-news-item-description {
        padding-left: 20px !important;
      }

      .main-news-item-date {
        padding: 5px 20px !important;
      }
    }
  }

  .main-news-items {
    &.grid-view {
      .main-news-item-image-wrapper {
        width: 100% !important;
      }

      .main-news-item-content {
        width: 100% !important;

        .main-news-item-title {
          font-size: 14px !important;
        }
      }
    }
  }

  // MATCH PAGE
  .match {
    .match-container {
      flex: 100% !important;
      max-width: 100% !important;
    }

    .match-sidebar {
      display: none !important;
    }
  }

  // DAYNEWS
  .daynews-news {
    .daynews-news-header {
      .daynews-news-title {
        font-size: 15px !important;
      }
    }
  }

  // FOOTER
  .footer {
    .wrapper {
      width: calc(100% - 100px);

      .footer-nav {
        .footer-link {
          margin-top: 2px !important;
        }
      }
    }
  }

  // CATEGORY NEWS
  .category-news {
    .category-news-header {
      .category-news-title {
        font-size: 15px !important;
      }
    }
  }

  // CHAMIPIONSHIP
  .championship {
    .championship-content {
      .matches-header {
        height: 70px !important;
        padding: 5px 0 !important;

        .matches-title {
          font-size: 15px !important;
        }
      }

      .standings-season {
        top: 5px !important;
      }

      .championship-history {
        padding: 15px !important;
      }
    }
  }

  // CLUB
  .club {
    .club-content {
      .matches-header {
        height: 70px !important;
        padding: 5px 0 !important;

        .matches-title {
          font-size: 15px !important;
        }
      }

      .club-history {
        padding: 15px !important;
      }
    }
  }

  .transfers {
    .transfer {
      .transfer-date {
        font-size: 12px !important;
      }
    }
  }

  // STATISTICS-PAGE
  .statistics-page {
    .statistics-page-header {
      padding: 0 30px !important;
    }

    .statistics-page-tabs {
      padding: 0 25px !important;
    }

    .statistics-page-content {
      .matches-header {
        .matches-title {
          font-size: 15px !important;
        }
      }
    }
  }

  // VIDEOS
  .videos {
    .videos-header {
      padding: 0 15px !important;

      .videos-title {
        font-size: 15px !important;
      }
    }

    .videos-content {
      .video-item {
        .video-item-content {
          padding: 10px 15px !important;
        }
      }
    }
  }

  // SEARCH RESULT PAGE
  .search-result {
    .search-result-header {
      font-size: 15px !important;
    }
  }

  // MATCH VIDEOS
  .match-video {
    .match-video-frame {
      height: 450px !important;
    }

    .match-video-player {
      height: 32vw !important;
    }
  }

  // HOME MAIN NEWS MATCH
  .home-main-news {
    .home-main-news-match-item {
      .main-news-item-match {
        padding: 10px 5px !important;
        height: 100px !important;

        .team {
          .team-name {
            font-size: 12px !important;
            line-height: 1.2em !important;
          }
        }

        .score {
          margin: 0 !important;

          .report {
            .data {
              padding: 0 5px !important;
            }
          }
        }
      }

      .home-main-news-item-title {
        .main-news-item-match {
          padding: 10px 5px !important;
          height: 80px !important;

          .team {
            padding-top: 0 !important;
            padding: 0 5px !important;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .team-image {
              width: 30px !important;
              height: 30px !important;
              margin-bottom: 4px !important;
            }

            .team-name {
              font-size: 12px !important;
              line-height: 1.2em !important;
            }
          }

          .score {
            margin: 0 !important;

            .report {
              .data {
                padding: 0 5px !important;
                height: 30px !important;
                line-height: 27px !important;
              }
            }

            .time {
              height: 30px !important;
              line-height: 1.5em !important;
            }
          }
        }
      }
    }
  }

  // PLAYER TABS IN CLUB SIDEBAR
  .player-tabs {
    .player-tab {
      font-size: 11px !important;
      margin: 0 !important;
      padding-top: 18px !important;
    }
  }

  // MATCH STADIUM
  .stadium {
    height: 410px !important;

    .team-formation {
      .col {
        height: 98% !important;
        margin-top: 1% !important;
      }
    }
  }
}

@media (max-width: 1440px) {
  .advanced-wrapper {
    width: 100% !important;
  }

  // HOME MAIN NEWS
  .home-main-news {
    .home-main-news-item {
      height: 300px !important;

      .home-main-news-item-image-wrapper {
        height: 160px !important;
      }

      .home-main-news-item-title {
        height: 110px !important;
        padding: 5px 15px !important;
        font-size: 13px !important;
      }

      .home-main-news-item-date {
        padding: 0 15px !important;
      }

      &.home-main-news-item-featured {
        .home-main-news-item-image-wrapper {
          height: 100% !important;
        }

        .home-main-news-item-title {
          height: auto !important;
          font-size: 15px !important;
        }
      }
    }
  }

  // HOME TOP RATED NEWS
  .home-top-rated-news {
    .home-top-rated-news-header {
      height: 50px !important;

      .home-top-rated-news-title {
        padding-left: 10px !important;
        font-size: 15px !important;
      }
    }

    .home-top-rated-news-items {
      height: 206px !important;
      padding: 0 10px 20px 10px !important;

      .home-top-rated-news-item {
        .home-top-rated-news-item-image-wrapper {
          height: 100px !important;

          .number {
            width: 25px !important;
            height: 25px !important;
          }
        }

        .home-top-rated-news-item-title {
          padding: 8px !important;
          font-size: 12.5px !important;
        }
      }
    }
  }

  // MATCH PAGE
  .stadium {
    height: 390px !important;

    // .team-formation {
    //     padding: 12px 0;
    // }
  }

  // MATCH VIDEOS
  .match-video {
    .match-video-frame {
      height: 30vw !important;
    }

    .match-video-player {
      height: 30vw !important;
    }
  }
}

@media (max-width: 1366px) {

  // TODAY MATCHES
  .today-matches {
    .today-matches-header {
      .today-matches-tabs {
        .league {
          .icon {
            height: 16px !important;
            margin-right: 4px !important;
          }
        }
      }
    }
  }

  // MY TEAMS
  .my-teams {
    .my-teams-content {
      .my-teams-content-news-1 {
        .my-teams-content-news-item:nth-child(3) {
          display: none;
        }
      }

      .my-teams-content-news-2 {

        .my-teams-content-news-item:nth-child(3),
        .my-teams-content-news-item:nth-child(4) {
          display: none;
        }
      }
    }
  }

  // MAIN NEWS
  .main-news-items {
    &.grid-view {
      .grid-item {
        width: calc(100% / 2 - 30px) !important;
      }
    }
  }

  //MAIN NEWS MATCH ITEM
  .main-news-match-item {
    .main-news-item-content {
      flex-direction: column;

      .main-news-item-match {
        .team {
          padding-top: 0 !important;

          .team-image {
            width: 30px !important;
            height: 30px !important;
            margin-bottom: 4px !important;
          }
        }

        .score {
          .report {
            .data {
              padding: 0 5px !important;
              height: 30px !important;
              line-height: 27px !important;
            }
          }

          .time {
            height: 30px !important;
            line-height: 28px !important;
          }
        }
      }
    }
  }

  // SHARED ITEMS
  .statistics {
    .statistic-normal-container {
      padding: 10px !important;
    }

    .player {
      margin-bottom: 4px !important;

      .player-image-wrapper {
        width: 40px !important;
        height: 50px !important;
      }

      .player-name {
        width: calc(100% - 85px) !important;
        padding-left: 4px !important;
        padding-right: 2px;
      }

      .player-point {
        width: 38px !important;
      }
    }
  }

  // LIVESCORES
  .livescores {
    .livescores-leagues {
      .livescore-league {
        .livescore-league-header {
          .league-image {
            margin-left: 10px !important;
            margin-right: 6px !important;
          }
        }

        .livescore-league-content {
          .livescores-match {
            .match-date {
              width: 80px !important;
              padding-left: 0 !important;
            }

            .match-info {
              width: calc(100% - 80px) !important;
              padding-right: 100px !important;
            }
          }
        }
      }
    }
  }

  // MATCH PAGE
  .match {
    .match-header {
      padding: 20px 0 !important;
    }

    .match-content {
      .highlights {
        .half-stats {
          padding: 15px !important;

          .half {
            margin-bottom: 15px !important;

            .half-header {
              height: 40px !important;
              line-height: 40px !important;
              margin-bottom: 15px !important;
            }
          }
        }
      }

      .match-statistics {
        margin-top: 15px !important;

        .match-statistics-header {
          padding: 15px !important;
        }

        .match-statistics-content {
          padding: 15px !important;

          .match-statistics-item {
            margin-bottom: 10px;
          }
        }
      }

      .match-formation {
        .formations {
          margin-bottom: 15px !important;

          .stadium {
            height: 300px !important;
          }
        }

        .lineup {
          padding: 15px !important;

          .lineup-header {
            height: 40px !important;
            line-height: 40px !important;
          }

          .lineup-content {
            .match-player {
              height: 40px !important;
            }
          }
        }
      }
    }
  }

  //video player
  .video-player-wrapper,
  .news-video,
  .news-description {
    iframe {
      width: 570px;
      height: 310px;
    }
  }

  // MATCH VIDEOS
  .match-video {
    .match-video-frame {
      height: 27vw !important;
    }

    .match-video-player {
      height: 27vw !important;
    }
  }

  // HOME MAIN NEWS MATCH
  .home-main-news {
    .home-main-news-match-item {
      &.home-main-news-item-featured {
        .home-main-news-item-title {
          flex-direction: column;
        }
      }

      .main-news-item-match {
        height: 80px !important;

        .team {
          .team-name {
            font-size: 12px !important;
          }
        }

        .score {
          .report {
            .data {
              height: 34px !important;
              line-height: 32px !important;
              font-size: 21px !important;
            }
          }

          .time {
            font-size: 9px !important;
          }
        }
      }

      .home-main-news-item-title {
        .main-news-item-match {
          .team {
            .team-name {
              font-size: 12px !important;
            }
          }

          .score {
            .report {
              .data {
                font-size: 21px !important;
              }
            }

            .time {
              font-size: 9px !important;
            }
          }
        }
      }
    }
  }

  // COMMENTS
  .comment {
    padding-top: 10px !important;
    padding-bottom: 10px !important;

    .comment-header {
      height: 40px !important;

      .comment-user {
        height: 40px !important;

        .comment-user-image {
          width: 40px !important;
          height: 40px !important;
          margin-right: 6px !important;
        }

        .comment-user-name {
          font-size: 13px !important;
        }
      }

      .comment-info {
        .top-comment-mark {
          height: 30px !important;
          line-height: 28px !important;
          padding: 0 6px !important;
          font-size: 11px !important;

          .star-icon {
            font-size: 13px !important;
            margin-right: 2px !important;
          }
        }

        .comment-likes {
          width: 60px !important;
        }

        .comment-team {
          width: 60px !important;
        }

        .comment-date {
          width: 120px !important;
          font-size: 12px !important;
        }
      }
    }
  }

  // MATCH PLAYER TABS IN CLUB SIDEBAR
  .player-tabs {
    .player-tab {
      padding-top: 15px !important;
      margin: 0 5px -1px 5px !important;
      font-size: 13px !important;
    }
  }
}

@media (max-width: 1280px) {
  .match {
    display: block !important;

    .match-container {
      flex: 100%;
      max-width: 100%;
    }

    .match-sidebar {
      flex: 100%;
      max-width: 100%;
    }
  }

  // MATCH VIDEOS
  .match-video {
    .match-video-frame {
      height: 400px !important;
    }

    .match-video-player {
      height: 400px !important;
    }
  }

  // COMMENTS
  .comment {
    .comment-header {
      height: 30px !important;

      .comment-user {
        height: 30px !important;

        .comment-user-image {
          width: 30px !important;
          height: 30px !important;
          margin-right: 4px !important;
        }

        .comment-user-name {
          font-size: 12px !important;
        }
      }

      .comment-info {
        .top-comment-mark {
          height: 30px !important;
          line-height: 28px !important;
          padding: 0 4px !important;
          font-size: 10px !important;

          .star-icon {
            font-size: 12px !important;
          }
        }

        .comment-likes {
          width: 54px !important;
        }

        .comment-team {
          width: 54px !important;
        }

        .comment-date {
          width: 100px !important;
          font-size: 11px !important;
        }
      }
    }
  }
}

@media (max-width: 1155px) {

  // MATCH PAGE
  .match {
    .match-content {
      .match-formation {
        .formations {
          .stadium {
            height: 280px !important;

            .stadium-player {
              // .icon {
              //     width: 26px !important;
              //     height: 26px !important;
              //     line-height: 23px !important;
              //     font-size: 13px !important;
              // }

              .name {
                height: 18px !important;
                line-height: 13px !important;
                font-size: 10px !important;
              }
            }
          }
        }
      }
    }
  }

  // MATCH VIDEOS
  .match-video {
    .match-video-frame {
      height: 340px !important;
    }

    .match-video-player {
      height: 340px !important;
    }
  }
}

// WRAPPER ADVANCED BREAKPOINTS

@media (max-width: 1779.98px) {
  .wrapper {
    width: 1300px;
  }
}

@media (max-width: 1599.98px) {
  .wrapper {
    width: 1140px;
  }
}

@media (max-width: 1439.98px) {
  .wrapper {
    width: 1070px;
  }
}

@media (max-width: 1365.98px) {
  .wrapper {
    width: 985px;
  }

  .web-promotion {

    &.web-news-top,
    &.web-news-bottom {
      margin: 0 -27px;
    }
  }
}

@media (max-width: 1279.98px) {
  .wrapper {
    width: 1145px;
  }

  .web-promotion {
    display: none;
  }
}

@media (max-width: 1154.98px) {
  .wrapper {
    width: 990px;
  }
}

@media (max-width: 1024px) {
  .desktop-screen {
    display: none !important;
  }

  .mobile-screen {
    display: inherit !important;
  }

  .wrapper {
    width: auto;
  }

  // share socials
  .sb-button {
    margin: 4px !important;
  }

  .sb-wrapper {
    height: 24px !important;
    width: 24px !important;
    min-width: auto !important;
    line-height: 24px !important;

    .sb-icon {
      font-size: 11px !important;
    }
  }

  //videos
  .videos {
    .videos-content {
      .video-item {
        .video-item-content {
          padding: 0 !important;
        }
      }
    }
  }

  .championship {
    .matches-header {
      .mat-form-field {
        .mat-form-field-infix {
          width: auto;
        }
      }
    }
  }

  .match .match-content .match-formation .formations {
    .stadium {
      width: 100% !important;
      max-width: 420px;
      height: 738px !important;
      margin: 10px auto;
      background-image: url(../assets/img/Pitch_mobile.png);
      flex-direction: column;

      .team-formation {
        max-width: 100%;
        flex-direction: column !important;
        padding: 22px 0;

        &.away {
          flex-direction: column-reverse !important;
        }

        .col {
          height: 100% !important;
          margin-top: 0;

          .team-formation-line {
            flex-direction: row !important;

            .stadium-player {
              .icon {
                width: 30px;
                height: 30px;
                font-size: 12px;
                line-height: 28px;
              }

              .name {
                // height: 24px !important
                // font-size: 11px !important;
                // font-family: 'HNeue75tdBd';
                // line-height: 20px !important;
              }
            }
          }
        }
      }
    }
  }

  .match-formation {
    .formations {
      .formations-header {
        &>* {
          padding: 0 15px;
        }
      }
    }
  }

  .cdk-overlay-pane {
    max-width: 100vw !important;

    .mat-dialog-container {
      padding: 10px;
    }
  }
}

//For overriding bootstrap
@media (min-width: 1367px) {

  /* grid columns xxl */
  .col-xxl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xxl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-xxl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-xxl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-xxl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xxl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-xxl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-xxl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xxl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-xxl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-xxl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xxl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-xxl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-xxl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
}


//Mobile
// MOBILE Bootstrap Breakpoints
@media (max-width: 991.98px) {

  // TABLES
  .tables {
    .tables-content {
      .tables-row {
        height: 38px !important;
        line-height: 38px !important;
        margin-top: 4px !important;

        &:hover {
          color: #2f3031 !important;
          background: #f4f4f4 !important;
        }

        .position {
          width: 22px !important;
          font-size: 12px !important;
          line-height: 38px !important;
        }
      }
    }
  }

  // STATISTICS
  .statistics {
    max-width: 400px;
    margin: 0 auto;

    .statistics-tabs {
      .statistics-tab {
        padding: 0 5px !important;
        margin: 0 10px !important;
      }
    }
  }
}

@media (max-width: 767.98px) {

  // MAIN NEWS ITEM
  .main-news-item {
    height: 160px !important;

    .main-news-item-image-wrapper {
      width: 200px !important;
      height: 160px !important;
    }

    .main-news-item-content {
      width: calc(100% - 200px) !important;

      .main-news-item-description {
        display: none !important;
      }
    }
  }

  .pagination {
    li {

      a,
      span {
        height: 38px !important;
        line-height: 36px !important;
        font-size: 14px !important;
        padding: 0 14px !important;
      }
    }
  }

  // MATCHES
  .matches {
    padding: 4px 10px !important;

    .matches-item {
      padding: 0 8px !important;
      font-size: 12px !important;

      .date {
        width: 50px !important;
      }

      .content {
        width: calc(100% - 50px) !important;
        padding-right: 50px !important;

        .team {
          width: auto !important;
          display: flex !important;
          align-items: center !important;
          flex: 1;

          &.home {
            justify-content: flex-end;
          }

          &.away {
            justify-content: flex-start;
          }

          .team-name {
            padding: 0 6px !important;
          }
        }

        .time {
          width: 60px !important;
          font-size: 13px !important;
        }
      }
    }
  }

  // LIVESCORES
  .livescores {
    padding: 8px !important;

    .livescores-header {
      margin-bottom: 16px !important;
      padding: 0 15px !important;
    }

    .livescores-leagues {
      .goal-sound-btn {
        margin-top: -14px !important;
        margin-bottom: 5px !important;
      }

      .livescore-league {
        .livescore-league-header {
          padding: 0 8px !important;
        }

        .livescore-league-content {
          .livescores-match {
            padding: 0 8px !important;

            .match-date {
              width: 60px !important;

              span {
                &:first-child {
                  padding-left: 10px !important;
                }
              }
            }

            .match-info {
              width: auto !important;
              padding-right: 80px !important;
              flex: 1;

              .team {
                width: auto !important;
                display: flex !important;
                align-items: center !important;
                flex: 1;

                &.home {
                  justify-content: flex-end;
                }

                &.away {
                  justify-content: flex-start;
                }

                .team-image {
                  margin: 0 !important;
                }

                .team-name {
                  font-size: 11px !important;
                  padding: 0 6px !important;
                }
              }

              .match-score {
                width: 50px !important;
                font-size: 13px !important;
              }
            }
          }
        }
      }
    }
  }

  // FOOTER
  .footer {
    .wrapper {
      width: 100% !important;
    }

    .footer-top {
      padding: 20px 0 !important;
    }

    .footer-bottom {
      .footer-logo {
        height: 40px !important;
        margin: 10px 0 !important;
      }

      .footer-text {
        line-height: 24px !important;
      }

      .footer-stats {
        margin: 16px 0 !important;
      }
    }
  }

  // CLUB
  .club {
    .club-header {
      overflow: hidden;

      .club-info {
        .club-image {
          height: 70px !important;
        }

        .club-name {
          margin-top: 5px !important;
        }

        .club-subscribe {
          margin: 10px !important;
        }
      }

      .club-tabs {
        padding: 0 10px !important;
        overflow-x: auto !important;
        overflow-y: hidden !important;
        display: block !important;
        white-space: nowrap;
        text-align: center;
        height: 60px !important;
        padding-bottom: 10px !important;
        margin-bottom: -10px;

        .club-tab {
          margin: 0 2px !important;
          padding-left: 5px !important;
          padding-right: 5px !important;
          font-size: 13px !important;
        }
      }
    }
  }

  // CHAMPIONSHIP
  .championship {
    .championship-header {
      overflow: hidden;

      .championship-info {
        .championship-image {
          height: 70px !important;
        }

        .championship-name {
          margin-top: 5px !important;
        }
      }

      .championship-tabs {
        padding: 0 10px !important;
        overflow-x: auto !important;
        overflow-y: hidden !important;
        display: block !important;
        white-space: nowrap;
        text-align: center;
        height: 60px !important;
        padding-bottom: 10px !important;
        margin-bottom: -10px;

        .championship-tab {
          margin: 0 2px !important;
          padding-left: 5px !important;
          padding-right: 5px !important;
          font-size: 13px !important;
        }
      }
    }

    .championship-content {
      .matches-header {
        height: 85px !important;
        flex-direction: column;
        padding-top: 10px !important;

        .matches-season-control {
          width: 100% !important;
          justify-content: center !important;
          margin-left: 0 !important;
        }
      }
    }
  }

  // USER PROFILE
  .user-profile {
    .user-profile-header {
      .user-tabs {
        padding: 0 6px !important;

        .user-tab {
          font-size: 12px !important;

          .count {
            width: 34px !important;
            height: 24px !important;
            line-height: 20px !important;
            font-size: 12px !important;
          }
        }
      }
    }
  }

  // PLAYER PAGE
  .player {
    .player-header {
      padding: 10px !important;

      * {
        font-size: 12px !important;
        padding: 0 !important;
      }

      .player-image {
        width: 100px !important;
        margin-left: 5px !important;
        margin-right: 10px !important;
      }

      .info {
        margin-bottom: 4px !important;

        span {
          height: 20px !important;
        }

        label {
          height: 20px !important;
          margin-bottom: 0 !important;
        }
      }

      .player-club-info {
        height: 90px !important;
        margin-top: 10px !important;
        padding: 0 5px !important;

        .club-logo {
          width: 60px !important;
          margin-right: 10px !important;
        }
      }
    }

    .player-content {
      .player-statistics-header {
        padding: 5px 0 !important;
      }

      .player-statistics-content {
        .player-statistics-box-top {
          padding: 10px !important;

          .info {
            span {
              font-size: 13px !important;
            }

            label {
              font-size: 15px !important;
            }
          }
        }

        .player-statistics-box-bottom {
          height: 50px !important;
          padding-left: 10px !important;
        }
      }
    }
  }

  // NEWS PAGE
  .news-page {
    .news-content {
      padding: 15px !important;

      .news-image {
        width: 100% !important;
        height: 300px !important;
        margin: 20px auto 16px auto !important;
      }

      .news-description {
        iframe {
          width: calc(100% - 100px);
          height: 372px;
        }
      }

      .news-tags {
        margin: 20px 0 !important;
      }
    }
  }

  //video player
  .video-player-wrapper {
    padding-top: 116px !important;
  }

  .news-description {
    iframe {
      width: calc(100% - 100px);
      height: 320px;
    }
  }

  // COMMENTS
  .comments {
    .comments-header {
      font-size: 15px !important;
      margin-bottom: 24px !important;
    }

    .comment-write {
      padding-bottom: 0 !important;
      background: url('../assets/img/comment_input.png') 16px center no-repeat;
      background-size: 24px 24px;

      .please-log-in {
        height: auto !important;
        font-size: 12px !important;
        padding-top: 5px !important;
        padding-bottom: 5px !important;
        background: #fff;

        .comment-auth-btn {
          padding: 4px 13px 7px 13px !important;
          margin-top: 4px !important;
          font-size: 12px !important;
        }
      }

      .comment-write-text {
        padding: 17px 17px 0px 50px !important;
        height: 53px !important;
        font-size: 12px !important;
        background: transparent;
      }

      .comment-write-btn {
        display: none !important;
      }
    }

    .comment {
      padding: 20px 0 !important;

      .comment-header {
        position: relative;
        height: 54px !important;
        align-items: flex-start !important;

        .comment-user {
          height: 32px !important;

          .comment-user-image {
            width: 32px !important;
            height: 32px !important;
            margin-right: 7px !important;
          }

          .comment-user-name {
            font-size: 12px !important;
          }
        }

        .comment-info {
          height: 32px !important;
          align-items: center;

          .top-comment-mark {
            width: 24px !important;
            height: 24px !important;
            line-height: 22px !important;
            padding: 0 !important;
            text-align: center;
            margin-right: 13px !important;
            font-size: 11px !important;

            .star-icon {
              font-size: 11px !important;
              margin-right: 0 !important;
            }
          }

          .comment-team {
            width: auto !important;
            border-right: 0 !important;
            margin-right: 11px !important;
          }

          .comment-likes {
            width: auto !important;
            border-right: 0 !important;

            .like-icon {
              font-size: 22px !important;
              margin-right: 4px !important;
            }
          }

          .comment-date {
            position: absolute;
            left: 0;
            bottom: 0;
            width: auto !important;
            height: auto !important;
            font-size: 12px !important;
          }
        }
      }

      .comment-content {
        font-size: 12px !important;
        padding: 14px 0 18px 0 !important;
      }

      .comment-footer {
        .comment-footer-option {
          font-size: 12px !important;
        }
      }
    }
  }

  .comment-replies {
    padding-left: 20px !important;
    margin-bottom: 12px !important;
  }
}

@media (max-width: 575.98px) {

  // SHARED
  .cdk-overlay-pane {
    .mat-dialog-container {
      .app-modal {
        padding: 8px !important;

        .modal-body {
          padding-left: 10px !important;
          padding-right: 10px !important;

          .form {
            .form-group {
              .form-control {
                height: 48px !important;
              }

              .submit-btn {
                height: 48px !important;
                line-height: 48px !important;
              }
            }
          }
        }
      }
    }
  }

  // HEADER
  .header {
    height: 70px !important;

    .logo {
      width: 150px !important;
      height: 44px !important;
    }

    .mobile-search .search {
      width: 38px !important;
      height: 36px !important;
      font-size: 20px !important;
      margin-right: 8px !important;
    }

    .auth {
      width: 38px !important;
      height: 36px !important;

      .auth-icon {
        width: 28px !important;
        height: 28px !important;
        background-size: 17px auto !important;
      }
    }

    .user {
      width: 76px !important;
      height: 36px !important;

      .user-icon {
        width: 37px !important;
        height: 35px !important;
      }

      .logout {
        height: 32px !important;
        width: 38px !important;
      }
    }

    .burger-nav {
      width: 44px !important;
      height: 44px !important;
      margin-left: 6px !important;
      font-size: 32px !important;
    }

    // open
    .search {
      .search-input-wrapper {
        height: 38px !important;
        animation: searchAnimate 250ms forwards !important;

        .search-btn {
          font-size: 21px !important;
          right: 2px !important;
        }
      }
    }
  }

  .collapsed {
    .header {
      height: 50px !important;
    }
  }

  //SUB MENU
  .nav {
    height: 50px !important;

    &.collapsed {
      height: 44px !important;
    }

    .nav-items {
      .nav-item {
        padding: 0 8px !important;

        .nav-link {
          font-size: 12px !important;
        }
      }
    }

  }

  // SEARCH POPUP
  .search-results {
    padding: 0 8px !important;
    padding-bottom: 10px !important;

    .result-section-title {
      font-size: 13px !important;
      margin-top: 10px !important;
      margin-bottom: 6px !important;
    }

    .tag {
      padding: 0 8px !important;

      .tag-title {
        font-size: 12px !important;
        padding-left: 2px !important;
      }

      .tag-subscribe {
        font-size: 11px !important;
      }
    }

    .news {
      height: 70px !important;

      .news-image {
        width: 110px !important;
      }

      .news-title {
        padding: 0 0 0 8px !important;
        font-size: 12px !important;
      }
    }
  }

  // MAIN NEWS ITEM
  .main-news-item {
    height: auto !important;
    //max-height: 325px !important;
    margin-bottom: 15px !important;
    flex-direction: column;
    border-radius: 5px !important;

    .main-news-item-image-wrapper {
      width: 100% !important;
      height: 220px !important;
    }

    .main-news-item-content {
      width: 100% !important;
      height: auto !important;
      //padding-bottom: 40px;
      padding-left: 15px;
      padding-right: 15px;

      .main-news-item-title {
        height: auto !important;
        overflow: hidden !important;
        padding: 15px !important;
        font-size: 15px !important;
        //border-bottom: 1px solid #E3E3E3;
      }

      .main-news-item-date {
        right: 4px;
        bottom: 6px !important;
        padding: 5px 15px !important;
        align-items: center !important;
        font-size: 12px !important;
      }
    }
  }

  //MAIN NEWS MATCH ITEM
  .main-news-match-item {
    flex-direction: column;
    height: auto !important;

    .main-news-item-image-wrapper {
      width: 100% !important;
      height: 200px !important;
    }

    .main-news-item-content {
      position: initial !important;
      width: 100% !important;
      height: auto !important;
      padding-bottom: 6px;

      //.main-news-item-match-wrapper {
      //    position: absolute;
      //    bottom: 90px;
      //    left: 0;
      //}
    }
  }

  .pagination {
    margin: 5px 0 !important;

    li {
      margin-left: 4px !important;
      margin-right: 4px !important;

      &:first-child,
      &:last-child {
        display: none !important;
      }

      a,
      span {
        height: 40px !important;
        line-height: 38px !important;
        font-size: 14px !important;
        padding: 0 11px !important;
        min-width: 40px !important;
        max-width: 46px !important;
      }
    }
  }

  // HOME TOP RATED NEWS
  .home-top-rated-news {
    padding: 0 !important;
    margin-top: 10px !important;

    .home-top-rated-news-header {
      height: 50px !important;

      .home-top-rated-news-title {
        font-size: 16px !important;
      }

      .home-top-rated-news-controls {
        font-size: 17px !important;
        height: 20px !important;

        .home-top-rated-news-control {
          margin: 0px 12px !important;
        }
      }
    }

    .home-top-rated-news-items {
      padding: 0 !important;
      padding-bottom: 10px !important;

      .home-top-rated-news-item {
        .home-top-rated-news-item-image-wrapper {
          height: 90px !important;
        }

        .home-top-rated-news-item-title {
          height: 102px !important;
          padding: 5px !important;
          font-size: 13px !important;
          line-height: 18px !important;
        }
      }
    }
  }

  // FOOTER
  .footer {
    .footer-top {
      .footer-nav {
        .footer-link {
          font-size: 14px !important;
          margin-right: 12px !important;
        }
      }
    }

    .footer-bottom {
      .footer-logo {
        height: 30px !important;
        margin: 10px 0 !important;
      }

      .footer-text {
        font-size: 13px !important;
        line-height: 20px !important;
      }
    }
  }

  // NEWS PAGE
  .news-page {
    .news-content {
      padding: 15px !important;

      .news-title {
        font-size: 18px !important;
      }

      .news-image {
        height: 220px !important;
      }

      .news-video {
        margin: 20px auto 16px auto !important;
      }

      .news-description {
        margin-top: 15px !important;

        iframe {
          width: 100%;
          height: 280px;
        }
      }

      .news-tags {
        .news-tag {
          margin-right: 6px !important;
          margin-bottom: 10px !important;
        }
      }
    }
  }

  // MATCH REPORT
  .match-article {
    padding: 15px !important;

    .match-article-title {
      font-size: 17px !important;
    }

    .match-article-image,
    .match-article-video {
      margin: 10px auto !important;
    }

    .match-article-description {
      margin-top: 15px !important;
      font-size: 13px !important;
    }
  }

  //CATEGORY NEWS
  .category-news {
    .category-news-header {
      height: 60px !important;
      padding: 0 15px !important;
      margin-bottom: 15px !important;

      .category-news-title {
        font-size: 16px !important;
      }
    }
  }

  // CLUB
  .club {
    .club-header {
      .club-tabs {
        .club-tab {
          font-size: 13px !important;
        }
      }
    }

    .club-content {
      .matches-header {
        height: 50px !important;

        .matches-title {
          font-size: 15px !important;
        }
      }

      .transfers {
        .transfer {
          .transfer-team-img {
            width: 20px !important;
            height: 20px !important;
            margin-right: 5px !important;
          }

          .transfer-team-name {
            font-size: 12px !important;
          }
        }
      }

      .player-group {
        .player-group-header {
          padding: 10px 10px 0 !important;
          font-size: 15px !important;
        }

        .player-group-content {
          padding: 10px !important;

          .club-player {
            .club-player-left {
              width: 100% !important;
              padding-top: 6px !important;
              padding-left: 8px !important;

              .club-player-image {
                border: 1px solid #f1f1f1 !important;
              }

              .club-player-info {
                width: auto !important;
              }
            }

            .club-player-right {
              width: 100% !important;
              padding: 6px !important;
              display: flex !important;
              justify-content: center !important;

              .club-player-right-list {
                label {
                  margin-bottom: 0 !important;
                }
              }
            }
          }
        }
      }
    }
  }

  // CHAMPIONSHIP
  .championship {
    .championship-header {
      .championship-tabs {
        .championship-tab {
          font-size: 13px !important;
        }
      }
    }

    .championship-content {
      .matches-header {
        .matches-title {
          font-size: 15px !important;
        }

        .matches-season-control {
          .mat-form-field.w50 {
            width: calc(50% - 10px) !important;
            margin: 0 5px !important;
          }

          .mat-form-field.w33 {
            width: calc(33% - 10px) !important;
            margin: 0 5px !important;
          }
        }
      }

      .standings-season {
        position: relative !important;
        right: auto !important;
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-radius: 15px;
        margin-bottom: 15px;
        padding: 0 30px;
        top: 0 !important;

        .mat-form-field {
          width: 100%;
          margin-top: 5px;
        }
      }
    }
  }

  // MATHCES
  .matches {
    padding: 2px 6px !important;

    .matches-item {
      position: relative;
      height: 50px !important;
      padding: 0 !important;
      font-size: 12px !important;

      .date {
        width: 50px !important;
        width: 50px !important;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgb(47 48 49 / 80%);
        padding-left: 5px;
      }

      .content {
        width: 100% !important;
        height: 100%;
        padding: 0 !important;
        display: block !important;
        text-align: left;

        .team {
          width: calc(100% - 50px) !important;
          height: 25px;
          display: flex !important;
          justify-content: flex-start !important;
          align-items: center;
          padding-left: 10px !important;

          .team-image {
            order: 1;
            width: 15px !important;
          }

          .team-name {
            order: 2;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }

        .time {
          width: 50px !important;
          height: 100%;
          position: absolute;
          right: 0;
          top: 0;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding: 3px 15px 5px 0;
          color: rgb(47 48 49 / 80%) !important;

          .score {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;

            span {
              &:nth-child(2) {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  // TABLES
  .tables-tabs {
    height: 60px !important;
    padding: 0 10px !important;

    .tables-tab {
      padding: 18px 4px !important;
    }
  }

  // PLAYER PAGE
  .player {
    .player-header {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      .player-club-info {
        width: auto !important;
        justify-content: center;

        .club-logo {
          margin-left: 20px;
        }

        .club-info {
          width: auto !important;
        }
      }
    }

    .player-tabs {
      justify-content: center;

      .player-tab {
        font-size: 12px !important;
      }
    }

    .player-content {
      .player-statistics-header {
        .player-statistics-title {
          font-size: 15px !important;
          padding: 5px 10px !important;
        }
      }

      .player-statistics-content {
        padding: 0 !important;

        .player-statistics-box-top {
          .info {
            span {
              font-size: 12px !important;
            }

            label {
              font-size: 13px !important;
            }
          }
        }

        .player-statistics-box-bottom {
          .bottom-item {
            padding-right: 15px !important;
            font-size: 13px !important;
          }
        }
      }
    }
  }

  // MY TEAMS
  .my-teams {
    .my-teams-header {
      padding: 10px 10px 0 10px !important;

      .my-teams-header-top {
        flex-direction: column;
        margin-bottom: 10px;

        .my-teams-header-title {
          font-size: 16px !important;
          padding: 5px 0;
        }

        .my-teams-header-search {
          position: relative !important;
          top: auto !important;
          right: auto !important;
          margin-top: 10px;

          .search {
            width: 100% !important;

            .search-results {
              max-height: calc(100vh - 257px);
              min-height: 200px !important;
              overflow-y: auto;
            }
          }
        }
      }

      .my-teams-tabs {
        .my-teams-tab {
          font-size: 13px !important;
          padding-top: 14px !important;
        }
      }
    }
  }

  // STATISTICS PAGE
  .statistics-page {
    .statistics-page-tabs {
      padding: 0 10px !important;
      justify-content: center;

      .statistics-page-tab {
        font-size: 12px !important;
        margin: 0 !important;
      }
    }
  }

  // LIVESCORES
  .livescores {
    .livescores-header {
      .livescores-tabs {
        margin-top: 10px;
        justify-content: center;

        .livescores-tab {
          margin-right: 0 !important;
          padding: 0 10px !important;
          font-size: 13px !important;

          .count {
            width: auto !important;
            height: auto !important;
            padding: 1px 10px 3px 10px !important;
            font-size: 14px !important;
          }
        }
      }
    }

    .livescores-calendar {
      margin-bottom: 15px !important;

      .calendar-control {
        width: 30px !important;
        font-size: 21px !important;
      }

      .calendar-items {
        width: calc(100% - 60px) !important;
      }
    }

    .livescores-leagues {
      .goal-sound-btn {
        margin-top: -8px !important;

        .sound-toggle {
          margin-right: -8px !important;
        }
      }

      .livescore-league {
        margin-bottom: 10px !important;

        .livescore-league-header {
          .league-image {
            width: 22px !important;
            height: 22px !important;
            margin-left: 15px !important;
          }

          .league-name {
            width: calc(100% - 70px) !important;
            font-size: 12px !important;
          }
        }

        .livescore-league-content {
          .livescores-match {
            height: 50px !important;

            .match-date {
              width: 60px !important;
              opacity: 0.85;
              padding-left: 5px !important;
            }

            .match-info {
              position: relative;
              width: calc(100% - 60px) !important;
              height: 100%;
              padding: 0 !important;
              padding-left: 5px !important;
              display: block !important;
              text-align: left;

              .team {
                width: calc(100% - 30px) !important;
                height: 25px;
                display: flex !important;
                justify-content: flex-start !important;
                align-items: center;

                .team-image {
                  order: 1;
                  width: 15px !important;
                }

                .team-name {
                  order: 2;
                }

                .goal-info {
                  font-size: 9px !important;
                  order: 3;
                  margin-left: auto !important;
                }
              }

              .match-score {
                width: 30px !important;
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding: 3px 15px 5px 0;
                color: rgb(47 48 49 / 80%) !important;

                .score {
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  height: 100%;

                  span {
                    &:nth-child(2) {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // USER PROFILE
  .user-profile {
    .user-profile-header {
      overflow: hidden;
      margin-top: 0 !important;
      margin-bottom: 15px !important;

      .user-info {
        padding: 0 15px;

        .user-image {
          height: 90px !important;
          top: auto !important;
        }
      }

      .user-tabs {
        overflow-x: auto;
        white-space: nowrap;
        padding-bottom: 10px !important;
        margin-bottom: -10px !important;

        .settings-tab {
          font-size: 12px !important;
          margin: 0 7px !important;
        }
      }
    }

    .user-profile-content {
      .user-subs {
        .user-subs-wrapper {
          .sub {
            margin-bottom: 12px !important;
            padding: 10px !important;
          }
        }
      }
    }
  }

  // MATCH PAGE
  .match {
    .match-header {
      padding: 15px 10px !important;

      .team {
        flex: 2;
        width: auto !important;
        padding: 0 5px !important;
        text-align: center !important;

        .team-image {
          width: 40px !important;
          height: 40px !important;
        }

        .team-name {
          font-size: 16px !important;
        }
      }

      .info {
        flex: 3;
        width: auto !important;
        padding: 0 5px !important;

        .full-result {
          font-size: 27px !important;
        }

        .result {
          font-size: 12px !important;
        }
      }
    }

    .match-tabs {
      .match-tab {
        font-size: 13px !important;
      }
    }

    .match-content {
      margin-top: 10px !important;

      .highlights {
        .half-stats {
          .half {
            .overview {
              padding: 10px 0 !important;

              .overview-item {
                .overview-item-name {
                  padding: 0 5px !important;
                }
              }

              &.left {
                .overview-item {

                  .overview-item-icon,
                  .arrow {
                    margin-left: 5px !important;
                  }
                }
              }

              &.right {
                .overview-item {

                  .overview-item-icon,
                  .arrow {
                    margin-right: 5px !important;
                  }
                }
              }
            }
          }
        }
      }

      .match-formation {
        .formations {
          .formations-header {
            font-size: 12px !important;

            &>* {
              padding: 0 5px !important;
            }
          }

          .stadium {
            width: calc(100% - 20px) !important;
            //height: 570px !important;
            margin: 0 auto 10px auto !important;
          }
        }

        .lineup {
          padding: 8px !important;
          margin-bottom: 14px !important;

          .lineup-content {
            .match-player {
              height: 44px !important;
              padding: 0 5px !important;

              .match-player-item {
                .number {
                  width: 20px !important;
                }

                .name {
                  font-size: 12px !important;
                  padding-left: 5px !important;
                }

                .goal {
                  padding: 0 !important;
                  font-size: 16px !important;
                  margin-left: 5px !important;
                }

                .yellow-card,
                .red-card {
                  width: 13px !important;
                  height: 18px !important;
                  margin-left: 5px !important;
                }

                .down-arrow,
                .up-arrow {
                  margin-left: 5px !important;
                }

                &.away {
                  .name {
                    padding-left: 0 !important;
                    padding-right: 5px !important;
                  }

                  .goal {
                    margin-left: 0 !important;
                    margin-right: 5px !important;
                  }

                  .yellow-card,
                  .red-card,
                  .down-arrow,
                  .up-arrow {
                    margin-left: 0 !important;
                    margin-right: 5px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  //video player
  .video-player-wrapper,
  .news-video,
  .news-description {
    iframe:not([id^="twitter-widget-"]) {
      width: 100% !important;
      height: 56vw !important;
    }
  }

  // MATCH VIDEOS
  .match-video {
    padding: 5px 10px !important;

    .match-video-frame {
      height: 53vw !important;
    }

    .match-video-player {
      height: 53vw !important;
    }
  }
}

@media (max-width: 375px) {

  // HEADER
  .header {
    height: 60px !important;

    .logo {
      width: 140px !important;
      height: 41px !important;
    }
  }

  //SUB MENU
  .nav {
    height: 44px !important;

    &.collapsed {
      height: 40px !important;
    }

    .nav-items {
      .nav-item {
        padding: 0 5px !important;

        .nav-link {
          font-size: 11px !important;
        }
      }
    }
  }


  // MAIN NEWS
  .main-news {
    .main-news-header {
      .main-news-title {
        font-size: 13px !important;
      }
    }
  }

  //MAIN NEWS MATCH ITEM
  .main-news-match-item {
    flex-direction: column;
    height: auto !important;

    .main-news-item-image-wrapper {
      width: 100% !important;
      height: 200px !important;
    }

    .main-news-item-content {
      width: 100% !important;
      height: auto !important;
    }
  }

  .pagination {
    li {
      margin-left: 2px !important;
      margin-right: 2px !important;

      a,
      span {
        height: 36px !important;
        line-height: 34px !important;
        font-size: 12px !important;
        padding: 0 4px !important;
        min-width: 36px !important;
        max-width: 40px !important;
      }
    }
  }

  // HOME TOP RATED NEWS
  .home-top-rated-news {
    .home-top-rated-news-header {
      height: 40px !important;

      .home-top-rated-news-title {
        font-size: 13px !important;
      }

      .home-top-rated-news-controls {
        font-size: 16px !important;
        height: 18px !important;
      }
    }

    .home-top-rated-news-items {
      .home-top-rated-news-item {
        .home-top-rated-news-item-image-wrapper {
          height: 80px !important;
        }

        .home-top-rated-news-item-title {
          height: 112px !important;
          font-size: 12px !important;
          line-height: 16px !important;
        }
      }
    }
  }

  // NEWS PAGE
  .news-page {
    .news-content {
      .news-image {
        height: 170px !important;
      }
    }
  }

  //CATEGORY NEWS
  .category-news {
    .category-news-header {
      height: 50px !important;

      .category-news-title {
        font-size: 14px !important;
      }
    }
  }

  // CLUB
  .club {
    .club-header {
      .club-tabs {
        .club-tab {
          font-size: 12px !important;
        }
      }
    }

    .club-content {
      .matches-header {
        .matches-title {
          font-size: 13px !important;
        }
      }

      .transfers {
        .transfer {
          .transfer-team-img {
            width: 18px !important;
            height: 18px !important;
          }

          .transfer-team-name {
            font-size: 11px !important;
          }
        }
      }

      .player-group {
        .player-group-header {
          font-size: 14px !important;
        }
      }
    }
  }

  // CHAMPIONSHIP
  .championship {
    .championship-header {
      .championship-tabs {
        .championship-tab {
          font-size: 12px !important;
        }
      }
    }

    .championship-content {
      .matches-header {
        .matches-title {
          font-size: 13px !important;
        }
      }
    }
  }

  // LIVESCORES
  .livescores {
    .livescores-header {
      margin-bottom: 8px !important;

      .livescores-tabs {
        margin-top: 5px !important;

        .livescores-tab {
          height: 50px !important;
          font-size: 12px !important;

          .count {
            font-size: 12px !important;
          }
        }
      }
    }

    .livescores-calendar {
      height: 50px !important;
      margin-bottom: 8px !important;

      .calendar-control {
        width: 24px !important;
        line-height: 45px !important;
      }

      .calendar-items {
        width: calc(100% - 48px) !important;
        font-size: 12px !important;
      }
    }

    .livescores-leagues {
      .livescore-league {
        .livescore-league-header {
          display: block !important;

          .league-image {
            width: 18px !important;
            height: 18px !important;
            margin-left: 10px !important;
            margin-right: 3px !important;
          }

          .league-name {
            width: calc(100% - 84px) !important;
            font-size: 11px !important;
          }

          .league-tables {
            position: absolute;
            right: 7px;
            top: 0;
            height: 100%;
            display: flex;
            align-items: center;
            font-size: 11px !important;

            .league-tables-text {
              display: none;
            }

            .league-tables-icon {
              display: block !important;
            }
          }
        }

        .livescore-league-content {
          .livescores-match {
            .match-date {
              width: 50px !important;
              padding-left: 0 !important;

              span {
                padding-left: 6px !important;
                font-size: 11px !important;
              }
            }

            .match-info {
              width: calc(100% - 50px) !important;
            }
          }
        }
      }
    }
  }

  // STATISTICS PAGE
  .statistics-page {
    .statistics-page-header {
      padding: 10px 5px !important;
      text-align: center;
      font-size: 16px !important;

      .statistics-page-select {
        padding: 5px !important;
      }
    }

    .statistics-page-content {
      .matches-header {
        padding: 10px 0 !important;

        .matches-title {
          height: 26px;
          font-size: 13px !important;
        }
      }
    }
  }

  .league-select-option {
    font-size: 13px !important;
    padding: 0 10px !important;
  }

  // MATCH PAGE
  .match {
    .match-header {
      padding: 10px 4px !important;

      .team {
        .team-name {
          font-size: 14px !important;
        }
      }

      .info {
        .full-result {
          font-size: 24px !important;
          margin-bottom: 5px !important;
        }

        .result {
          font-size: 11px !important;
        }
      }
    }

    .match-tabs {
      .match-tab {
        font-size: 12px !important;
      }
    }

    .match-content {
      .highlights {
        .half-stats {
          .half {
            .overview {
              padding: 14px 0 !important;

              .number {
                width: 19px !important;
                font-size: 11px !important;
              }

              .overview-item {
                .overview-item-name {
                  font-size: 11px !important;
                }
              }
            }
          }
        }
      }

      .match-statistics {
        .match-statistics-item {
          margin-bottom: 14px !important;

          .match-statistics-item-header {
            font-size: 13px !important;

            &>* {
              padding: 0 8px !important;
            }

            .title {
              font-size: 11px !important;
            }
          }

          .match-statistics-item-content {
            margin-top: 4px !important;

            .left,
            .right {
              padding: 0 8px !important;
            }
          }
        }
      }

      .match-formation {
        .formations {
          .formations-header {
            font-size: 11px !important;
          }

          .stadium {
            width: calc(100% - 16px) !important;
            //height: 520px !important;
          }
        }

        .lineup {
          .lineup-content {
            .match-player {
              height: 46px !important;
              padding: 0 3px !important;

              .match-player-item {
                .number {
                  width: 14px !important;
                  font-size: 11px !important;
                }

                .name {
                  font-size: 11px !important;
                  padding-left: 3px !important;
                }

                .goal {
                  font-size: 14px !important;
                  padding-left: 3px !important;
                }

                .yellow-card,
                .red-card {
                  width: 11px !important;
                  height: 15px !important;
                  margin-left: 3px !important;
                }

                .down-arrow,
                .up-arrow {
                  margin-left: 3px !important;
                }

                &.away {
                  .name {
                    padding-left: 0 !important;
                    padding-right: 3px !important;
                  }

                  .goal {
                    padding-left: 0 !important;
                    padding-right: 3px !important;
                  }

                  .yellow-card,
                  .red-card,
                  .down-arrow,
                  .up-arrow {
                    margin-left: 0 !important;
                    margin-right: 3px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .main-news-tabs {
    .main-news-tab {
      font-size: 13px;
    }
  }

  //User favorites
  .user-favorites-modal {
    padding: 0 10px !important;

    .user-favorites-modal-title {
      font-size: 16px !important;
    }

    .user-favorites-modal-content {
      .favorites-search-item {
        .favorites-search-item-title {
          font-size: 11px !important;
        }
      }
    }
  }
}


@media (max-width: 359.98px) {
  .mobile-promotion {

    &.mobile-news-top,
    &.mobile-news-bottom {
      margin: 10px -6px 10px -6px;
    }
  }
}

// Height
@media (max-height: 699.98px) and (max-width: 991.98px) {
  .header-mobile-wrapper {
    .header {
      height: 50px !important;

      .logo {
        width: 120px !important;
        height: 35px !important;
      }
    }
  }
}
